<template>    
    <div class="searchBanner innerSearch auto_search position-relative" v-bind:class="( search !='' ) ? 'active' : '' "> 
        <div class="form-group position-relative searchBox">       
            <input type="text" class="form-control w-100" placeholder="Search this blog" v-model="search" @keyup ="checkwhattoCall()">
            <a href="javascript:void(0)" class="sr-icon position-absolute searchBoxIcon">
                <vue-feather type="search"></vue-feather>
            </a>
        </div>
        <ul class="search-listing text-left w-100 mb-0 mt-3" v-if="searchResult.length > 0">
            <li class="hvr-grow-shadow semibold mb-3" v-for="i,index in searchResult" v-on:click="articlesFunInfo(i.articleSlug)" :key="index">
                <router-link :to="entityPath+'/trading-exchange-guide/'+i.catSlug+'/'+i.articleSlug">
                    <search-icon></search-icon>
                    {{ i.articleName }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>  
    //import { SearchIcon  } from 'vue-feather-icons'
    export default {
    name: 'AutoSeach',
    data () {
        return {
            "addClass" : 0,
            search : '',
            searchResult : [],              
        }
    },    
    props : [
        "pageName",
        "list"
    ],
    methods: {
        checkwhattoCall(){
            try{
                if(this.search == ''){                    
                    this.searchResult = []
                }else{                                     
                    this.searchResult =  this.list.searchArr.filter(allCategoryData => {
                        return (`${allCategoryData.articleName}`.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    });                  
                }
            }catch(e){
                this.searchResult = []
            }
        },
        articlesFunInfo(slug){
            if(this.pageName =="articlesDetails"){
                this.$parent.articlesInfo(slug)
                this.search = ""
                this.searchResult = []
            }
        }        
    },
    // components: {
    //    SearchIcon,
    // },
    mounted () {
       
    }
  }
</script>