<template>
    <div>
     <div v-if="pageLoader" class="loading">
            <div class="load_img">
                <!-- <img src="../../../public/assets/images/loader.gif" alt="loader" width="100" height="75" class="img-fluid" /> -->
            </div>
        </div>
        <section class="blockElement themeBG">
            <div class="container">
                <div class="row diraction">
                    <div class="col-12 col-lg-4 mt-3">
                        <nav class="mb-3 mb-lg-0">
                            <ol class="breadcrumb mb-0">
                                <li class="breadcrumb-item">
                                    <router-link class="themecolor" :to="entityPath+'/help-centre'"><h6 class="mb-0 semibold">Home</h6></router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page" v-if="Object.keys(faqslist).length > 0">
                                    
                                        {{faqslist.dataArr[categorySlug][0].categoryName}}
                                    
                                    
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-12 col-lg-8">
                        <app-AutoSearch :pageName="'articles'" :list="faqslist"></app-AutoSearch>                        
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement faqBlock lightgreybg">
            <div class="container">
                <div class="mb-4 mb-lg-5" v-if="Object.keys(faqslist).length > 0">
                    <h2 class="mb-0">{{faqslist.dataArr[categorySlug][0].categoryName}}</h2>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="leftSidebar lightgrayBG">
                            <h4 class="secondary mb-0 pb-4">Categories</h4>
                            <ul v-if="Object.keys(faqslist).length > 0" class="listdDot">
                                <li class="" v-bind:class="( value[0].catSlug == categorySlug ) ? 'active' : '' " v-for="(value, name, index) in faqslist.dataArr" v-on:click="categoryArticles(value[0].catSlug)" :key="index">
                                    <router-link :to="entityName1+'/help-centre/'+value[0].catSlug">
                                        <!-- <chevron-right-icon></chevron-right-icon> -->
                                        {{value[0].categoryName}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8" >
                        <ul class="mt-3 mt-lg-0" v-if="Object.keys(faqslist).length > 0">
                            <li class="article-list-item semibold mb-3" v-for="(value, name, index) in faqslist.dataArr[categorySlug]" :key="index">
                                
                                <router-link :to="entityName1+'/help-centre/'+value.catSlug+'/'+value.articleSlug" class="hvr-grow-shadow f-18">
                                    {{value.articleName}}
                                    <!-- <link-icon></link-icon> -->
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
     //import { LinkIcon, ChevronRightIcon  } from 'vue-feather-icons'
    // import { finViewMixins } from '@/plugins/finView'

     import autoSearch from '@/components/shared/auto_search'
    import commonAllmixins from "@/plugins/commonAll";
    
    export default {
        name: 'HOMES',        
       mixins:[commonAllmixins],
        data () {
            return {                   
                "categorySlug" : "",
                loaderFunction : '1'  ,
                pageLoader : false,
                entityName1 : ''
            }
        },
        methods: {            
            categoryArticles(categoryName){
                this.categorySlug = categoryName
            }           
        },
        // computed: {
        //     categories: function () {
        //         try{                    
        //             return this.$store.getters['Get_Faqs_List'].dataArr
        //         }catch(e){
        //             return {}
        //         }
        //     }
        // },        
        created() {

            // if(this.$store.getters['Get_Faqs_List'] != ''){
            //     this.loaderFunction = '0'
            // }

            this.entityName1 = ((this.entityName == 'global')?'/global' : '/au');
            this.categoryArticles(this.$route.params.category)
            
            this._runGetFaqsList({'globalParams':{},'localParams':{'parameter':this}}) // GET Categories
        },        
        mounted() {  
            window.scroll(0,0)
        },
        components: {  
          //LinkIcon,          
          //ChevronRightIcon,
          appAutoSearch: autoSearch,  
        }
    }
</script>
